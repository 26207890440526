body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'StolzlBold';
  font-display: block;
  src: local('StolzlBold'), url(./assets/fonts/Stolzl\ Bold.ttf);
}

@font-face {
  font-family: 'StolzlBook';
  font-display: block;
  src: local('StolzlBook'), url(./assets/fonts/Stolzl\ Book.ttf);
}

@font-face {
  font-family: 'StolzlRegular';
  font-display: block;
  src: local('StolzlRegular'), url(./assets/fonts/Stolzl-Regular.ttf);
}

@font-face {
  font-family: 'StolzlMedium';
  font-display: block;
  src: local('StolzlMedium'), url(./assets/fonts/Stolzl-Medium.ttf);
}

@font-face {
  font-family: 'Titan';
  font-display: block;
  src: local('Titan'), url(./assets/fonts/TitanOne-Regular.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width:6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

div::-webkit-scrollbar:horizontal {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #595959;
  border-radius: 4px;
}